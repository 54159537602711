<template>
	<div>
		<div class="row mb-2">
			<div class="col-sm-8"  v-if="options.filters.length > 0">
				<h4 class="text-center"> Filtros </h4>
				<table class="listar_filtros table table-striped">
					<thead>
						<th>Relación</th>
						<th>Columna</th>
						<th>Operador</th>
						<th>Valor 1</th>
						<th>Valor 2</th>
						<th>&nbsp;</th>
					</thead>
					<tbody>
						<tr v-for="(filtro, index) in options.filters">
							<td>{{ (filtro.relacion == 'and' ? 'Obligatorio' : 'Opcional') }}</td>
							<td>{{ obtener_texto_filtro(buscar_columna(filtro.columna)) }}</td>
							<td>{{ buscar_operador(filtro.operador) }}</td>
							<td>{{ obtener_text_filto_seleccionado(filtro.columna, filtro.valor1) }}</td>
							<td>{{ filtro.valor2 }}</td>
							<td><button class="btn warning-btn" :value="index" @click="borrar_filtro">Borrar</button></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="col-sm-12 d-flex justify-content-end">
				<div class="col-md-3">
					<button class="btn principal-btn" id="dataGrid-btn-add-filter" @click="on_agregar_filtro">Agregar filtro</button>
				</div>
			</div>
		</div>
		<br />
		<div class="row mb-2">
			<div class="col-sm-12 d-flex justify-content-end">
				<slot/>
			</div>
		</div>
		<br />
		<table class="table table-striped dataGrid-data">
			<thead>
				<th>
					<input class="checkbox-style" type="checkbox" :name="obtener_nombre+'_all'" :id="obtener_nombre+'_all'" @click="seleccionar_todas">
					<label class="label-checkbox-style" :for="obtener_nombre+'_all'"> </label>
				</th>
				<th v-for="(text, value) in config.cols" :key="value" @click="ordenar_rows(value, text)" :class="(is_ordeable(text) ? 'ordeable' : '')+(options.order_col == value && is_ordeable(text) ? ' activo' : '')"><span v-if="is_ordeable(text)" class="icon-embed"></span>{{ col_text(text) }}</th>
			</thead>
			<tbody v-if="data.length > 0">
				<tr v-for="row in data" :style="obtener_rows_estilos('style',row)" :class="obtener_rows_estilos('class',row)">
					<td>
						<input class="checkbox-style" type="checkbox" :name="obtener_nombre" :id="obtener_nombre+'_'+row.id" :value="row.id" :data-json="toJson(row)" @click="seleccionar_uno">
						<label class="label-checkbox-style" :for="obtener_nombre+'_'+row.id"> </label>
					</td>
					<td  v-for="(text, value) in config.cols" :key="value" v-html="mutaciones(value, row[value], row)"></td>
				</tr>
			</tbody>
			<tbody v-else>
				<tr>
					<td :colspan="(Object.keys(config.cols).length + 1)" class="text-center">No hay registros para mostrar</td>
				</tr>
			</tbody>
		</table>
		<div v-if="total_paginas > 0" class="row">
			<div class="col-sm-8" style="padding-top: 44px;">
				<div class="overflow-auto">
					<nav>
						<ul class="pagination">
							<li v-if="config.paginator.pagina_actual > 1" class="page-item" @click="cambiar_pagina('anterior')"><span class="page-link">Anterior</span></li>
							<li v-else class="page-item disabled"><span class="page-link">Anterior</span></li>

							<li v-for="pag in paginas_a_mostrar" :class="'page-item'+(pag == config.paginator.pagina_actual ? ' active' : '')" @click="cambiar_pagina(pag)"><span class="page-link">{{ pag }}</span></li>

							<li v-if="config.paginator.pagina_actual < total_paginas" class="page-item" @click="cambiar_pagina('siguiente')"><span class="page-link">Siguiente</span></li>
							<li v-else class="page-item disabled"><span class="page-link">Siguiente</span></li>
						</ul>
					</nav>
				</div>
			</div>
			<div class="col-sm-3 offset-sm-1">
				<div class="row">
					<div class="col-sm-12 text-right" style="padding:10px 20px;color:#666">
						Totales: Registros {{ config.paginator.total_registros }} | Páginas {{ total_pages }}
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12">
						<select v-model="options.limit" @change="update_limit" class="form-control">
							<option value="5">Mostrar 5 registros por página</option>
							<option value="10">Mostrar 10 registros por página</option>
							<option value="20">Mostrar 20 registros por página</option>
							<option value="50">Mostrar 50 registros por página</option>
							<option value="100">Mostrar 100 registros por página</option>
							<option value="200">Mostrar 200 registros por página</option>
							<option value="500">Mostrar 500 registros por página</option>
						</select>
					</div>
				</div>
			</div>
		</div>

		<Modal v-if="show_agregar_filtro" width="70vw" class="bpb-modal" @close="cancelar_filtro">
			<h2 class="title">Agregar filtro</h2>
			<div class="body">
				<div class="row form-group">
					<div :class="clases_rows+' text-center'">Relación</div>
					<div class="col-sm-3 text-center">Columna</div>
					<div :class="clases_rows+' text-center'">Operador</div>
					<div class="col-sm-3 text-center">Valor 1</div>
					<div v-if="filtro.operador === 'between' || filtro.operador == 'not_between'" class="col-sm-2 text-center">Valor 2</div>
				</div>
				<div class="row form-group">
					<div :class="clases_rows">
						<select v-model="filtro.relacion" name="relacion" id="relacion" class="form-control">
							<option value="and">Y</option>
							<option value="or">O</option>
						</select>
					</div>
					<div class="col-sm-3">
						<select v-model="filtro.columna" name="filtro_columna" id="filtro_columna" class="form-control" @keypress="agregar_filtro_enter">
							<option v-for="(value, key) in config.filters.cols" :value="key">{{ obtener_texto_filtro(value) }}</option>
						</select>
					</div>
					<div :class="clases_rows">
						<select v-model="filtro.operador" name="filtro_operador" id="filtro_operador" class="form-control" @keypress="agregar_filtro_enter">
							<option v-for="(value, key) in operators" :value="key">{{ value }}</option>
						</select>
					</div>
					<div class="col-sm-3">
						<input v-if="filtro_col_is_text" v-model="filtro.valor1" type="text" class="form-control" name="valor1" id="valor1" placeholder="Valor 1" @keypress="agregar_filtro_enter">
						<select v-else v-model="filtro.valor1" name="valor1" id="valor1" class="form-control">
							<option v-for="(value, key) in obtener_options" :value="key">{{ value }}</option>
						</select>
					</div>
					<div v-if="filtro.operador === 'between' || filtro.operador == 'not_between'" class="col-sm-2">
						<input v-model="filtro.valor2" type="text" class="form-control" name="valor2" id="valor2" placeholder="Valor 2" @keypress="agregar_filtro_enter">
					</div>
				</div>
			</div>
			<div class="footer mb-2">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-md-3">
							<button class="btn principal-btn dataGrid-filter-btn-add" @click="agregar_filtro">Agregar</button>
						</div>
						<div class="col-md-3">
							<button class="btn complementary-btn dataGrid-filter-btn-cancel" @click="cancelar_filtro">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'

	export default {
		components: {
			Modal
		}
		,props: {
			config: {
				type: Object
				,default: () => ({
					name: 'datagrid'
					,cols: {
						prubea: 'Prueba'
						,prueba2: 'Prueba 2'
						,prueba3: 'Prueba 3'
						,prueba4: 'Prueba 4'
						,prueba5: 'Prueba 5'
					}
					,filters: {
						cols: {
							prubea: 'ColFilter'
							,prueba2: 'ColFilter 2'
							,prueba3: 'ColFilter 3'
							,prueba4: 'ColFilter 4'
							,prueba5: 'ColFilter 5'
						}
					}
					,paginator: {
						total_registros: 280
						,registros_por_pagina: 20
						,pagina_actual: 1
					}
					,mutators: {
						prueba4: function(data) {
							return 'Hola mundo!';
						}
					}
					,style: {
						rows: {
							estatus: {
								value: 1
								,color: '#E6E6E6'
							}
						}
					}
				})
			}
			,data: {
				type: Array
				,default: () => ([])
			}
			,select: {
				type: Boolean
				,default: false
			}
			,filters: {
				type: Array
				,default: () => ([])
			}
		}
		,data: () => ({
			show_agregar_filtro: false
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,filtro: {
				relacion: 'and'
				,columna: null
				,operador: null
				,valor1: null
				,valor2: null
			}
			,operators: {
				equal_to: 'Es igual a'
				,not_equal_to: 'No es igual a'
				,less_than: 'Menos que'
				,greater_than: 'Mayor que'
				,between: 'Entre'
				,not_between: 'No entre'
				,contains: 'Contiene'
				,starts_with: 'Comienza con'
				,ends_with: 'Termina con'
				,in_the_past: 'En el pasado'
				,in_the_next: 'En el siguiente'
				,in_the_peroid: 'En el periodo'
			}
			,seleccionados: []
			,filtro_col_is_text: true
			,load_popState: false
			,total_pages: 1
		})
		,updated: function() {
			this.$emit('ready');
		}
		,mounted: function() {
			this.options.page = 1; //this.$helper.getUrlParam('page', 1);
			this.options.limit = 20; //this.$helper.getUrlParam('limit', 20);

			if (this.filters.length > 0)
				this.options.filters = Object.assign(this.options.filters, this.filters);

			this.$emit('actions', 'options', this.options);

			if (!this.load_popState)
				window.onpopstate = (e) => {
					this.options.page = this.$helper.getUrlParam('page', 1);
					this.options.limit = this.$helper.getUrlParam('limit', 20);
				}
		}
		,methods: {
			toJson: function(data) {
				return JSON.stringify(data);
			}
			,executeBtn: function(btn) {
				if (btn.action && typeof(btn.action) == 'function')
					btn.action();
			}
			,on_agregar_filtro: function() {
				this.filtro.columna = Object.keys(this.config.filters.cols)[0];
				this.filtro.operador = Object.keys(this.operators)[0];
				this.show_agregar_filtro = true;
			}
			,agregar_filtro_enter: function(e) {
				if (e.keyCode == 13) {
					this.agregar_filtro();
				}
			}
			,agregar_filtro: function() {
				let options = Object.assign({}, this.options);

				options.filters.push(this.filtro);
				options.page = 1;
				options.limit = 20;
				this.$helper.setUrlParam('page', 1);
				this.options = Object.assign({}, options);

				// this.$emit('actions', 'options', val);

				this.cancelar_filtro();
			}
			,borrar_filtro: function(e) {
				let filtros = [];

				for(let i=0; i<this.options.filters.length; i++) {
					if (e.target.value != i) {
						filtros.push(this.options.filters[i]);
					}
				}

				this.options.filters = filtros;
			}
			,cancelar_filtro: function() {
				this.filtro = {
					relacion: 'and'
					,columna: null
					,operador: null
					,valor1: null
					,valor2: null
				}
				this.show_agregar_filtro = false;
			}
			,buscar_columna: function(col) {
				return this.config.filters.cols[col];
			}
			,buscar_operador: function(operator) {
				return this.operators[operator];
			}
			,ordenar_rows: function(col_name, col) {
				if (this.is_ordeable(col)) {
					if (this.options.order_col == col_name)
						this.options.order_dir = this.options.order_dir != 'asc' ? 'asc' : 'desc';
					else
						this.options.order_dir = 'asc';

					this.options.order_col = col_name;
					this.$helper.setUrlParam('page', 1);
					this.$options.page = 1;
				}
			}
			,cambiar_pagina: function(pag) {
				// this.$log.info('pag', pag);
				if (pag == 'anterior')
					this.options.page = this.config.paginator.pagina_actual - 1;
				else if (pag == 'siguiente')
					this.options.page = this.config.paginator.pagina_actual + 1;
				else
					this.options.page = pag;

				this.$helper.setUrlParam('page', this.options.page);
			}
			,seleccionar_todas: function(e) {
				let seleccionados = [];
				document.querySelectorAll('input[type=checkbox][name='+this.config.name+']').forEach(checkbox => {
					checkbox.checked = e.target.checked;

					if (checkbox.checked)
						seleccionados.push(JSON.parse(checkbox.getAttribute('data-json')));
				});
				this.seleccionados = seleccionados;
			}
			,seleccionar_uno: function(e) {
				if (e.target.checked)
					this.seleccionados.push(JSON.parse(e.target.getAttribute('data-json')));
				else {
					if (this.seleccionados.length > 1) {
						let seleccionados = [];

						this.seleccionados.forEach(row => {
							if (row.id != parseInt(e.target.value))
								seleccionados.push(row);
						});

						this.seleccionados = seleccionados;
					}else
						this.seleccionados = [];
				}
			}
			,mutaciones: function(col, value, row) {
				if (this.config.mutators && Object.keys(this.config.mutators).indexOf(col) != -1 && typeof(this.config.mutators[col]) == 'function')
					return this.config.mutators[col](value, row, this, this.$store.state);
				else
					return value;
			}
			,is_ordeable: function(col) {
				if (typeof(col.ordeable) == 'boolean')
					return col.ordeable;
				else
					return true;
			}
			,col_text: function(col) {
				return col.text ? col.text : col;
			}
			,filter_col_is_text: function() {
				let col = this.filtro.columna;
				let isText = true;

				if (this.config.filters.cols[col])
					if (typeof(this.config.filters.cols[col]) != 'strinig')
						isText = false;

				return isText;
			}
			,obtener_texto_filtro: function(col) {
				if (typeof(col) == 'string')
					return col;
				else
					return col.text;
			}
			,obtener_text_filto_seleccionado: function(col, val) {
				// this.$log.info('col', col);
				// this.$log.info('val', val);
				if (typeof(this.config.filters.cols[col]) == 'string')
					return val;
				else
					return this.config.filters.cols[col].options[val];
			}
			,obtener_rows_estilos: function(type, row) {
				let result = '';

				if (this.config.style && this.config.style.rows) {
					let cols = Object.keys(this.config.style.rows);

					for(let i=0; i<cols.length; i++) {
						if (typeof(row[cols[i]]) != 'undefined') {
							if (!Array.isArray(this.config.style.rows[cols[i]])) {
								// this.$log.info('No es array');
								if (row[cols[i]] == this.config.style.rows[cols[i]].value) {
									if (this.config.style.rows[cols[i]].color && type == 'style') {
										// this.$log.info('es del tipo color');
										result = 'background-color: '+this.config.style.rows[cols[i]].color;
									}else {
										// this.$log.info('es del tipo clase');
										result = this.config.style.rows[cols[i]].class;
									}
									i=cols.length;
								}
							}else {
								// this.$log.info('es Array');
								let cols_value = this.config.style.rows[cols[i]]
								for(let a=0; a<cols_value.length; a++) {
									if (row[cols[i]] == cols_value[a].value) {
										if (cols_value[a].color && type == 'style') {
											result = 'background-color: '+cols_value[a].color;
										}else {
											result = cols_value[a].class;
										}
										a = cols_value.length;
									}
								}
							}
						}
					}
				}

				return result;
			}
			,update_limit: function() {
				this.$helper.setUrlParam('limit', this.options.limit);
			}
		}
		,computed: {
			paginas_a_mostrar: function() {
				let total = Math.ceil(this.config.paginator.total_registros / this.config.paginator.registros_por_pagina);
				this.total_pages = total;

				let paginas = [];

				let numPag = 3;
				let inicio = 1;

				if (this.config.paginator.pagina_actual > numPag)
					inicio = this.config.paginator.pagina_actual - numPag;

				let final = total;

				if ((this.config.paginator.pagina_actual+numPag) < total)
					final = this.config.paginator.pagina_actual+numPag;

				for(let i=inicio; i<=final; i++) {
					paginas.push(i);
				}

				return paginas;
			}
			,total_paginas: function() {
				return Math.ceil(this.config.paginator.total_registros / this.config.paginator.registros_por_pagina);
			}
			,obtener_options: function() {
				let columna = this.filtro.columna

				return this.config.filters.cols[columna].options;
			}
			,obtener_nombre: function() {
				return this.config.name ? this.config.name : 'dataGrid';
			}
			,numeros_totales: function() {
				let totales = '';
			}
			,clases_rows: function() {
				if (['between','not_between'].indexOf(this.filtro.operador) > -1)
					return 'col-sm-2';

				return 'col-sm-3';
			}
		}
		,watch: {
			options: {
				handler: function(val, oldVal) {
					this.$emit('actions', 'options', val);
				}
				,deep: true
			}
			,seleccionados: {
				handler: function(val, oldVal) {
					this.$emit('actions', 'selected', val);
				}
				,deep: true
			}
			,filtro: {
				handler: function(val, oldVal) {
					let col = val.columna;
					// this.$log.info('col', col);

					// this.$log.info('cols', this.config.filters.cols);
					this.filtro_col_is_text = typeof(this.config.filters.cols[col]) == 'string';
				}
				,deep: true
			}
			,select: function(val, oldVal) {
				if (!val) {
					document.querySelectorAll('input[type=checkbox][name='+this.config.name+']').forEach(checkbox => {
						checkbox.checked = false;
					});

					this.seleccionados = [];
				}
			}
			,filters: {
				handler: function(val, oldVal) {
					this.options.filters = val;
				}
				,deep: true
			}
		}
	}
</script>

<style lang="scss" scoped>
	th:first-child {
		text-align: left;
	}

	th.ordeable {
		cursor: pointer;
	}

	th.activo {
		color: #87EC73;
	}
	.icon-embed {
		font-size: 0.8em;
		transform: rotate(-90deg);
  		display: inline-block;
	}
	.icon-embed:before {
		content: "\ea7f";
	}

</style>